import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { ThemeProvider } from '@xstyled/styled-components'

const theme = {
  colors: {
    primaryFont: '#fff',
    secondaryFont: '###ffbc85',
    primaryOrange: 'orange',
    secondaryOrange: '#d15e00',
    darkOrange: '#4d2200',
    primaryBackground: '#222222',
    secondaryBackground: '#333333',
    tertiaryBackground: '#4F4F4F',
  },
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
